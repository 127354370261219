import React from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

export default function custom404() {
  return (
    <Layout>
      <GatsbySeo
        noindex={true}
        nofollow={true}
        title="404"
        description="Whoops, you've taken a wrong turn"
        canonical="https://yourtherapydoncaster.co.uk/"
        openGraph={{
         url: "https://yourtherapydoncaster.co.uk/",
         title: "404",
         description: "Whoops, you've taken a wrong turn",
         site_name: "YourTherapy",
       }}
      />
      <Container fluid>
        <Row >
          <Col xs={12} sm={{span:10,offset:1}} md={{span:6,offset:3}} className="text-center my-5">
            <h1 class="display-1">404</h1>
            <p>Whoops, either the page you were looking for has moved or never existed and someone has given you the wrong link.</p>
            <Button href="/" className="my-3 py-2 text-uppercase font-weight-bolder" variant="outline-dark">
              Go home
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
